import { useApi } from '../../lib/use-api/use-api'
import { GlobalLoadingState } from '../molecules/global-loading-state'

export type LoaderProps<DATA> = {
  apiUsage: (() => Promise<DATA>) | null | undefined
  element: (data: DATA) => JSX.Element
}

/**
 * @deprecated This component forces you to use a pattern which will cause multiple server calls.
 * Do as in BackofficeContractPage instead.
 * @param element
 * @param apiUsage
 * @constructor
 */
export function Loader<DATA>({ element, apiUsage }: LoaderProps<DATA>): JSX.Element {
  if (!apiUsage) {
    return <GlobalLoadingState />
  }
  return <InitializedLoader element={element} apiUsage={apiUsage} />
}

type InitializedLoaderProps<DATA> = {
  apiUsage: () => Promise<DATA>
  element: (data: DATA) => JSX.Element
}

function InitializedLoader<DATA>({ element, apiUsage }: InitializedLoaderProps<DATA>): JSX.Element {
  const apiResult = useApi(apiUsage)
  return apiResult.ready ? element(apiResult.data) : <GlobalLoadingState />
}
